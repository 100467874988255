<!-- src/views/Profile.vue -->

<template>
  <div>
    <h1>Welcome to your Profile</h1>
    <div v-if="loggedIn">
      <p>User Information:</p>
      <ul>
        <li><strong>Username:</strong> {{ userInfo.username }}</li>
        <li><strong>Full Name:</strong> {{ userInfo.fullname }}</li>
        <!-- Add other user information properties as needed -->
      </ul>
      <!-- <button @click="logout">Logout</button> -->
    </div>
    <div v-else>
      <p>Please log in to view your profile.</p>
      <router-link to="/">Go to Home</router-link>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    loggedIn() {
      return this.$store.getters['auth/isLoggedIn']
    },
    userInfo() {
      return this.$store.getters['auth/userInfo']
    }
  },
  // Fetch user information when the component is created
  created() {
    ////console.log('we are here as ', this.loggedIn)
    //this.$store.dispatch('auth/handleGoogleCallback')
    /* if (!this.loggedIn) {
      router.push('home')
    } */
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
    }
  }
}
</script>
